.container {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.text-container{
  width: 60%;
  margin: auto;
  margin-bottom: 60px;;

}

.title{
  text-align: center;
  margin-bottom: 40px;
}

.bio{
  font-size: large;
}

.image-card {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 2px;
  justify-content: center;
  height: 90%;
}

.image{
  object-fit: cover;
  width:100%;
  height:100%;
}

.card{
  margin-bottom: 30px;
}

.tags{
  text-align: center;
  padding: 20px 0px;
  margin-bottom: 30px;

}

.tag-btn{
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0px 20px;
  cursor: pointer;
  font-size: large;
}

.active{
  font-weight: bold;
  border-bottom: 1px solid rgb(8, 0, 0);
  background-color: rgb(243, 240, 237);
}

@media only screen and (max-width: 992px) {
  /* For mobile phones: */
  .container {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .tag-btn {
    text-align: center;
    width: 100%;
    display: block;
    margin: 0 0;
    margin-bottom: 40px;
  }



  .container {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
  }

}
